import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'
import {addProps} from 'ad-hok'

import Link from 'components/Link'
import {styleShorthandPropTypes} from 'components/View'
import {addTranslationHelpers} from 'util/i18n'

const getDispensaryMenuUrl = ({slug}) =>
  `https://leafly.com/dispensary-info/${slug}`

const ViewMenuLink = flow(
  addTranslationHelpers,
  addProps(({dispensary: {pickupAvailable}, t}) => ({
    label: pickupAvailable
      ? t('buttons.viewPickupMenu')
      : t('buttons.viewMenu'),
  })),
  ({dispensary, label, t, ...styleShorthandProps}) => (
    <Link to={getDispensaryMenuUrl(dispensary)} {...styleShorthandProps}>
      {label}
    </Link>
  )
)

ViewMenuLink.propTypes = {
  dispensary: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    pickupAvailable: PropTypes.bool.isRequired,
  }).isRequired,
  ...styleShorthandPropTypes,
}

export default ViewMenuLink
