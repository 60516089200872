import React from 'react'
import {flow, filter as ffilter, map as fmap} from 'lodash/fp'
import {assign, keys} from 'lodash'
import {addProps} from 'ad-hok'
import PropTypes from 'prop-types'

import {callWith, fmapWithKey, removeProps} from 'util/fp'

const styleProps = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}

const getStyleFromProps = props =>
  callWith(styleProps)(
    flow(
      fmapWithKey((styles, propName) => ({styles, propName})),
      ffilter(({propName}) => props[propName]),
      fmap('styles'),
      styleObjects => assign({}, ...styleObjects)
    )
  )

export const transformStyleProps = flow(
  addProps(({style = {}, ...props}) => ({
    style: {...getStyleFromProps(props), ...style},
  })),
  removeProps(keys(styleProps))
)

const View = flow(
  transformStyleProps,
  addProps(({as = 'div'}) => ({
    Tag: as,
  })),
  ({as, Tag, ...props}) => <Tag {...props} />
)

export const styleShorthandPropTypes = {
  flexRow: PropTypes.bool,
  flexColumn: PropTypes.bool,
  justifyContentCenter: PropTypes.bool,
  justifyContentEnd: PropTypes.bool,
  alignItemsCenter: PropTypes.bool,
  alignItemsEnd: PropTypes.bool,
  marginLeftAuto: PropTypes.bool,
  marginTopAuto: PropTypes.bool,
  spaceBetween: PropTypes.bool,
}

View.propTypes = {
  as: PropTypes.string,
  ...styleShorthandPropTypes,
}

export default View
