import React from 'react'
import {flow} from 'lodash/fp'
import {addProps} from 'ad-hok'
import PropTypes from 'prop-types'

const Address = flow(
  addProps(({dispensary: {address1, address2, formattedShortLocation}}) => ({
    formattedAddress: `${address1}${
      address2 ? `, ${address2}` : ''
    }, ${formattedShortLocation.replace(/,/, '')}`,
  })),
  ({formattedAddress}) => <span>{formattedAddress}</span>
)

Address.propTypes = {
  dispensary: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    // city: PropTypes.string.isRequired,
    formattedShortLocation: PropTypes.string.isRequired,
  }).isRequired,
}

export default Address
