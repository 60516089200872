import React from 'react'
import numeral from 'numeral'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import View from 'components/View'

const formatRating = rating => numeral(rating).format('0.0')

const Stars = () => null

const Rating = flow(
  addTranslationHelpers,
  ({t, dispensary: {starRating, numberOfReviews: numReviews}}) => (
    <View flexRow style={styles.ratingContainer}>
      <span style={styles.ratingNumber}>{formatRating(starRating)}</span>
      <Stars rating={starRating} />
      <span>{t('dispensaries.reviewCount', {numReviews})}</span>
    </View>
  )
)

Rating.propTypes = {
  dispensary: PropTypes.shape({
    starRating: PropTypes.number.isRequired,
    numberOfReviews: PropTypes.number.isRequired,
  }).isRequired,
}

export default Rating

const styles = {
  ratingNumber: {
    width: 60,
  },
  ratingContainer: {
    width: 100,
  },
}
