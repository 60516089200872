import React from 'react'
import View from 'components/View'
import PropTypes from 'prop-types'
import {flow, take as ftake} from 'lodash/fp'
import {addProps, addStateHandlers} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import Dispensary from 'components/Dispensary'

const INITIAL_NUM_SHOWN = 4
const SEE_MORE_SIZE = 4

const Dispensaries = flow(
  addStateHandlers(
    {numShown: INITIAL_NUM_SHOWN},
    {onSeeMore: ({numShown}) => () => ({numShown: numShown + SEE_MORE_SIZE})}
  ),
  addProps(({totalNumDispensaries, numShown, dispensaries}) => ({
    canSeeMore: totalNumDispensaries > numShown,
    shownDispensaries: ftake(numShown)(dispensaries),
  })),
  addTranslationHelpers,
  ({shownDispensaries, onSeeMore, canSeeMore, t}) => (
    <>
      <View as="ul" flexColumn style={styles.container}>
        {shownDispensaries.map(dispensary => (
          <Dispensary dispensary={dispensary} as="li" key={dispensary.id} />
        ))}
      </View>
      {canSeeMore && (
        <Button onClick={onSeeMore} label={t('dispensaries.seeMore')} />
      )}
    </>
  )
)

Dispensaries.propTypes = {
  dispensaries: PropTypes.arrayOf(
    PropTypes.shape({id: PropTypes.number.isRequired}).isRequired
  ).isRequired,
  totalNumDispensaries: PropTypes.number.isRequired,
}

export default Dispensaries

const styles = {
  container: {
    flexGrow: 1,
  },
  distanceContainer: {
    width: 70,
  },
  pickupContainer: {
    width: 80,
  },
}
