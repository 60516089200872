import React from 'react'
import {returns, flowMax, addStateHandlers, branch, addState} from 'ad-hok'
import {LoadScriptNext, GoogleMap, Marker} from '@react-google-maps/api'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import {GOOGLE_MAPS_API_KEY} from 'config/google-maps'
import {latLngPropType} from 'util/propTypes'
import View from 'components/View'
import Dispensary from 'components/Dispensary'

const Error = flow(
  addTranslationHelpers,
  ({t}) => <span>{t('map.error')}</span>
)

const DispensaryMarker = ({dispensary: {primaryLocation}, onClick}) => (
  <Marker position={primaryLocation} onClick={onClick} />
)

DispensaryMarker.propTypes = {
  dispensary: PropTypes.shape({
    primaryLocation: latLngPropType.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

const Map = flowMax(
  addStateHandlers(
    {hasError: false},
    {onError: () => () => ({hasError: true})}
  ),
  branch(({hasError}) => hasError, returns(() => <Error />)),
  addState('selectedDispensary', 'setSelectedDispensary'),
  ({
    dispensaries,
    center,
    onError,
    selectedDispensary,
    setSelectedDispensary,
  }) => (
    <View flexColumn style={styles.container} data-testid="map-container">
      <LoadScriptNext googleMapsApiKey={GOOGLE_MAPS_API_KEY} onError={onError}>
        <GoogleMap
          center={center}
          mapContainerStyle={styles.mapContainer}
          zoom={12}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {dispensaries.map(dispensary => (
            <DispensaryMarker
              dispensary={dispensary}
              onClick={() => {
                setSelectedDispensary(dispensary)
              }}
              key={dispensary.id}
            />
          ))}
        </GoogleMap>
      </LoadScriptNext>
      {selectedDispensary && (
        <View
          flexRow
          justifyContentCenter
          style={styles.selectedDispensaryContainer}
        >
          <Dispensary
            dispensary={selectedDispensary}
            testId="selected-dispensary"
          />
        </View>
      )}
    </View>
  )
)

Map.propTypes = {
  dispensaries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  center: latLngPropType.isRequired,
}

export default Map

const styles = {
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  mapContainer: {
    flexGrow: 1,
  },
  selectedDispensaryContainer: {
    position: 'absolute',
    bottom: 12,
    left: 0,
    right: 0,
  },
}
