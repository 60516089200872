import {useTranslation} from 'react-i18next'

// eslint-disable-next-line import/prefer-default-export
export const addTranslationHelpers = props => {
  const [
    t,
    // i18n
  ] = useTranslation()

  return {...props, t}
}
