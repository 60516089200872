import React, {Suspense} from 'react'

import './i18n-init'
import View from 'components/View'
import BrandFinder from 'components/BrandFinder'

import 'style/reset.css'
// import './App.css'

const App = () => (
  <Suspense fallback={<span>loading</span>}>
    <View flexColumn style={styles.container}>
      <BrandFinder />
    </View>
  </Suspense>
)

export default App

const styles = {
  container: {
    minHeight: '100vh',
  },
}
