import React from 'react'
import moment from 'moment-timezone'
import {flow} from 'lodash/fp'
import {addProps} from 'ad-hok'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import {invoke} from 'util/fp'
import View from 'components/View'

const getTimeOfDayString = date => date.format('HH:mm:ss')

const getIsOpen = ({todaySchedule: {open, close}, nowTimezone}) =>
  getTimeOfDayString(open) < getTimeOfDayString(nowTimezone) &&
  getTimeOfDayString(nowTimezone) < getTimeOfDayString(close)

const getMessage = ({todaySchedule: {close}, t}) =>
  t('selectedDispensary.closesAt', {time: close.format('h:mm A')})

const Hours = flow(
  addProps(({dispensary: {timeZone}}) => ({
    nowTimezone: moment().tz(timeZone),
  })),
  addProps(({nowTimezone, dispensary: {schedule}}) => ({
    todaySchedule: invoke(
      flow(
        () => nowTimezone.format('dddd').toLowerCase(),
        todayName => schedule[todayName],
        ({open, close}) => ({
          open: moment.tz(open, 'UTC'),
          close: moment.tz(close, 'UTC'),
        })
      )
    ),
  })),
  addProps(({nowTimezone, todaySchedule}) => ({
    isOpen: getIsOpen({todaySchedule, nowTimezone}),
  })),
  addTranslationHelpers,
  addProps(({isOpen, todaySchedule, t}) => ({
    message: getMessage({isOpen, todaySchedule, t}),
  })),
  ({t, isOpen, message}) => (
    <View flexRow>
      {isOpen ? t('selectedDispensary.open') : t('selectedDispensary.closed')} –{' '}
      {message}
    </View>
  )
)

Hours.propTypes = {
  dispensary: PropTypes.shape({
    schedule: PropTypes.object.isRequired,
  }).isRequired,
}

export default Hours
