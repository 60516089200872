import React from 'react'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'
import {addProps} from 'ad-hok'

import View from 'components/View'
import Distance from 'components/Distance'
import ViewMenuLink from 'components/ViewMenuLink'
import Rating from 'components/Rating'
import Hours from 'components/Hours'
import Address from 'components/Address'
import colors from 'style/colors'

const Dispensary = flow(
  addProps(({testId, dispensary: {slug}}) => ({
    testId: testId || `dispensary-${slug}`,
  })),
  ({dispensary: {name}, dispensary, as, testId}) => (
    <View as={as} flexColumn style={styles.container} data-testid={testId}>
      <View flexRow spaceBetween>
        <em>{name}</em>
        <Distance dispensary={dispensary} />
      </View>
      <View flexRow spaceBetween>
        <View flexColumn>
          <Address dispensary={dispensary} />
          <Hours dispensary={dispensary} />
          <Rating dispensary={dispensary} />
        </View>
        <View flexColumn justifyContentEnd alignItemsEnd>
          <ViewMenuLink dispensary={dispensary} />
        </View>
      </View>
    </View>
  )
)

Dispensary.propTypes = {
  dispensary: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  as: PropTypes.string,
  testId: PropTypes.string,
}

export default Dispensary

const styles = {
  container: {
    borderRadius: 4,
    backgroundColor: colors.white,
  },
}
