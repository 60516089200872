import React from 'react'
import {addProps, addStateHandlers} from 'ad-hok'
import {flow, map as fmap, sortBy as fsortBy} from 'lodash/fp'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import View from 'components/View'
import dummyData from 'data/static-finder-data.json'
import Map from 'components/Map'
import Button from 'components/Button'
import Dispensaries from 'components/Dispensaries'

const Navbar = flow(
  addTranslationHelpers,
  ({t}) => <h1>{t('brandFinder.findARetailer')}</h1>
)

const BottomBar = flow(
  addTranslationHelpers,
  ({isShowingMap, onChooseMap, onChooseList, t}) => (
    <View
      flexRow
      spaceBetween
      alignItemsCenter
      style={styles.bottomBarContainer}
    >
      {isShowingMap ? (
        <Button onClick={onChooseList} label={t('bottomBar.list')} />
      ) : (
        <Button onClick={onChooseMap} label={t('bottomBar.map')} />
      )}
      <Button onClick={() => {}} label={t('bottomBar.filter')} />
    </View>
  )
)

BottomBar.propTypes = {
  isShowingMap: PropTypes.bool.isRequired,
  onChooseMap: PropTypes.func.isRequired,
  onChooseList: PropTypes.func.isRequired,
}

const addDummyPickup = addProps(({data: {dispensaries}, data}) => ({
  data: {
    ...data,
    dispensaries: dispensaries.map((dispensary, dispensaryIndex) => ({
      ...dispensary,
      pickupAvailable: dispensaryIndex % 2 === 1,
    })),
  },
}))

const transformLatLng = addProps(({data}) => ({
  data: {
    ...data,
    dispensaries: fmap(({primaryLocation, ...dispensary}) => ({
      ...dispensary,
      primaryLocation: {
        lat: primaryLocation.lat,
        lng: primaryLocation.lon,
      },
    }))(data.dispensaries),
  },
}))

const addDummyData = flow(
  addProps({data: dummyData}),
  addDummyPickup,
  transformLatLng,
  addProps(({data}) => ({
    data: {
      ...data,
      centerLatLng: {lat: 47.6127099, lng: -122.3007719},
      totalNumDispensaries: data.dispensaries.length,
    },
  })),
  addProps(({data}) => ({
    data: {
      ...data,
      dispensaries: fsortBy('distanceMi')(data.dispensaries),
    },
  }))
)

const BrandFinder = flow(
  addDummyData,
  addStateHandlers(
    {isShowingMap: true},
    {
      onChooseMap: () => () => ({isShowingMap: true}),
      onChooseList: () => () => ({isShowingMap: false}),
    }
  ),
  ({
    data: {centerLatLng, dispensaries, totalNumDispensaries},
    isShowingMap,
    onChooseMap,
    onChooseList,
  }) => (
    <View flexColumn style={styles.container}>
      <Navbar />
      {isShowingMap ? (
        <Map dispensaries={dispensaries} center={centerLatLng} />
      ) : (
        <Dispensaries
          dispensaries={dispensaries}
          totalNumDispensaries={totalNumDispensaries}
        />
      )}
      <BottomBar
        onChooseMap={onChooseMap}
        onChooseList={onChooseList}
        isShowingMap={isShowingMap}
      />
    </View>
  )
)

export default BrandFinder

const styles = {
  container: {
    maxWidth: 480,
    flexGrow: 1,
  },
  bottomBarContainer: {
    paddingLeft: 17.5,
    paddingRight: 24,
    height: 44,
  },
}
