import React from 'react'
import PropTypes from 'prop-types'

const Button = ({onClick, label, style}) => (
  <button
    type="button"
    onClick={onClick}
    style={{...styles.container, ...style}}
  >
    {label}
  </button>
)

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
}

export default Button

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
