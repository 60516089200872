import React from 'react'
import numeral from 'numeral'
import PropTypes from 'prop-types'

const formatDistance = distanceInMiles =>
  `${numeral(distanceInMiles).format('0.0')} mi`

const Distance = ({dispensary: {distanceMi}}) => (
  <span>{formatDistance(distanceMi)}</span>
)

Distance.propTypes = {
  dispensary: PropTypes.shape({distanceMi: PropTypes.number.isRequired})
    .isRequired,
}

export default Distance
