import React from 'react'
import {addProps} from 'ad-hok'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'

import {transformStyleProps, styleShorthandPropTypes} from 'components/View'

const Link = flow(
  addProps(({style = {}}) => ({
    style: {...style, ...styles.overrides},
  })),
  transformStyleProps,
  ({to, ...props}) => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={to} {...props} />
  )
)

Link.propTypes = {
  to: PropTypes.string.isRequired,
  ...styleShorthandPropTypes,
}

export default Link

const styles = {
  overrides: {
    color: 'inherit',
  },
}
